import Vue from 'vue';
import Router from 'vue-router';

const RouteView = {
  render (h) {
    return h('router-view');
  },
};

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    component: (resolve) => require(['../views/Home/Index.vue'], resolve),
  },
  {
    path: '/fshg.cc/business',
    component: RouteView,
    children: [
      {
        path: '/CS',
        component: (resolve) =>
          require(['../views/Business/CapitalServices/Index.vue'], resolve),
      },
      {
        path: '/TECH',
        component: (resolve) =>
          require(['../views/Business/HighTech/Index.vue'], resolve),
      },
      {
        path: '/EE',
        component: (resolve) =>
          require(['../views/Business/EnergyEnvironment/Index.vue'], resolve),
      },
      {
        path: '/SCM',
        component: (resolve) =>
          require(['../views/Business/GlobalSupplyChain/Index.vue'], resolve),
      },
      {
        path: '/BM',
        component: (resolve) =>
          require(['../views/Business/Commercial/Index.vue'], resolve),
      },
      {
        path: '/HI',
        component: (resolve) =>
          require(['../views/Business/HealthIndustry/Index.vue'], resolve),
      },
      {
        path: '/BI',
        component: (resolve) =>
          require(['../views/Business/Construction/Index.vue'], resolve),
      },
      {
        path: '/AD',
        component: (resolve) =>
          require(['../views/Business/FutureAgriculture/Index.vue'], resolve),
      },
      {
        path: '/business/index',
        component: (resolve) =>
          require(['../views/Business/Index.vue'], resolve),
      },
      {
        path: '/business/petrochemical',
        component: (resolve) =>
          require(['../views/Business/Petrochemical/Index.vue'], resolve),
      },
      {
        path: '/business/ironOre',
        component: (resolve) =>
          require(['../views/Business/IronOre/Index.vue'], resolve),
      },
      {
        path: '/business/gas',
        component: (resolve) =>
          require(['../views/Business/Gas/Index.vue'], resolve),
      },
    ],
  },
  {
    path: '/newsTrend',
    component: RouteView,
    redirect: '/newsTrend/list',
    children: [
      {
        path: '/newsTrend/list',
        component: (resolve) =>
          require(['../views/NewsTrends/Index.vue'], resolve),
      },
      {
        path: '/newsTrend/detail/:newsId',
        component: (resolve) =>
          require(['../views/NewsTrends/NewsDetail.vue'], resolve),
      },
      {
        path: '/companyInformation',
        component: (resolve) =>
          require(['../views/CompanyInformation/Index.vue'], resolve),
      },
      {
        path: '/companyInformation/detail/:newsId',
        component: (resolve) =>
          require(['../views/CompanyInformation/InformDetail.vue'], resolve),
      },
      {
        path: '/policyRegulations',
        component: (resolve) =>
          require(['../views/PolicyRegulations/Index.vue'], resolve),
      },
      {
        path: '/policyRegulations/detail/:newsId',
        component: (resolve) =>
          require(['../views/PolicyRegulations/InformDetail.vue'], resolve),
      },
      {
        path: '/industryDynamics',
        component: (resolve) =>
          require(['../views/IndustryDynamics/Index.vue'], resolve),
      },
      {
        path: '/IndustryDynamics/detail/:newsId',
        component: (resolve) =>
          require(['../views/IndustryDynamics/InformDetail.vue'], resolve),
      },
    ],
  },
  {
    path: '/about',
    component: RouteView,
    children: [
      {
        path: '/about',
        component: (resolve) =>
          require(['../views/About/CompanyIntroduction/Index.vue'], resolve),
      },
      {
        path: '/CU',
        component: (resolve) =>
          require(['../views/About/ContactUs/Index.vue'], resolve),
      },
      {
        path: '/about/socialResponsibility',
        component: (resolve) =>
          require(['../views/About/SocialResponsibility/Index.vue'], resolve),
      },
      {
        path: '/about/partyBuilding',
        component: (resolve) =>
          require(['../views/About/PartyBuilding/Index.vue'], resolve),
      },
      {
        path: '/about/pbDetail/:pbId',
        component: (resolve) =>
          require(['../views/About/PartyBuilding/PbDetail.vue'], resolve),
      },
      {
        path: '/about/energyDevelopmentCommittee',
        component: (resolve) =>
          require([
            '../views/About/EnergyDevelopmentCommittee/Index.vue',
          ], resolve),
      },
    ],
  },
  {
    path: '/recruit',
    component: RouteView,
    children: [
      {
        path: '/recruit/talentStrategy',
        component: (resolve) =>
          require(['../views/Recruit/TalentStrategy/Index.vue'], resolve),
      },
      {
        path: '/recruit/socialRecruit',
        component: (resolve) =>
          require(['../views/Recruit/SocialRecruit/Index.vue'], resolve),
      },
    ],
  },
  {
    path: '/investor',
    component: RouteView,
    redirect: '/cooperationOffer',
    children: [
      {
        path: '/investor',
        component: (resolve) =>
          require(['../views/Invest/Investor/Index.vue'], resolve),
      },
      {
        path: '/investor/detail/:newsId',
        component: (resolve) =>
          require(['../views/Invest/Investor/NewsDetail.vue'], resolve),
      },
      {
        path: '/cooperationOffer',
        component: (resolve) =>
          require(['../views/Invest/CooperationOffer/Index.vue'], resolve),
      },
      {
        path: '/businesspartner',
        component: (resolve) =>
          require(['../views/Invest/BusinessPartner/Index.vue'], resolve),
      },
    ],
  },
];

// hack router push callback
const originalPush = Router.prototype.push;
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(Router);
export default new Router({
  // history
  mode: 'history',
  scrollBehavior: (to) => {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});
