import Vue from 'vue';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import router from './router/';
import store from './store/';
import App from './App.vue';

import filters from './filters/';

import './core/index';

Vue.use(ElementUI);

// Vue.filter(名字,函数)
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

/* eslint-disable */
new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App),
});
