import Vue from 'vue';
import Vuex from 'vuex';
import scrollModule from './scroll';
import navModule from './nav';
import webModule from './web';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    scroll: scrollModule,
    nav: navModule,
    web: webModule,
  },
});
