<template>
  <div
    id="app"
    class="scroll-y-container"
    v-infinite-scroll="load"
    :infinite-scroll-distance="infiniteScrollDistance"
    @scroll="handleScroll"
    v-resize="handleResize"
  >
    <router-view></router-view>
    <transition name="nav">
      <div
        v-if="navShow"
        class="nav-wrapper"
      >
        <NavBar
          :theme="navTheme"
          :bgColor="navBgColor"
          :shadow="navShadow"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import NavBar from '@/components/NavBar.vue';

export default {
  name: 'App',
  components: {
    NavBar,
  },
  data () {
    return {
      defaultTheme: {
        dark: {
          bgColor: '#ffffff00',
          shadow: false,
        },
        light: {
          bgColor: '#ffffffff',
          shadow: true,
        },
      },
    };
  },
  computed: {
    ...mapState({
      scrollTop: state => state.scroll.scrollTop,
      infiniteScrollDistance: state => state.scroll.infiniteScrollDistance,
      navShow: state => state.nav.show,
      navTheme: state => state.nav.theme,
      navBgColor: state => state.nav.bgColor,
      navShadow: state => state.nav.shadow,
    }),
    routerPath () {
      return this.$route.path;
    },
  },
  watch: {
    $route () {
      this.forceScroll(0);
      this.setNavShow(true);
    },
  },
  created () {
    this.$event.$on('nav-theme', (theme, options = {}) => {
      if (theme in this.defaultTheme) {
        const { bgColor, shadow } = { ...this.defaultTheme[theme], ...options };
        this.setNavTheme(theme);
        this.setNavBgColor(bgColor);
        this.setNavShadow(shadow);
      }
    });
  },
  methods: {
    ...mapMutations(['setScrollTop', 'forceScroll', 'setNavShow', 'setNavTheme', 'setNavBgColor', 'setNavShadow', 'setWebScale']),
    handleScroll (e) {
      if (this.scrollTop < e.target.scrollTop) {
        this.setNavShow(false);
      }
      if (this.scrollTop > e.target.scrollTop) {
        this.setNavShow(true);
      }
      this.setScrollTop(e.target.scrollTop);
    },
    load () {
      this.$event.$emit('load-more');
    },
    handleResize (app) {
      document.getElementsByTagName('html')[0].style.cssText = `
        font-size: ${ app.offsetWidth < 1920 ? app.offsetWidth / 1920 : 1 }px;
      `;
      this.setWebScale(app.offsetWidth < 1920 ? app.offsetWidth / 1920 : 1);
    },
  },
};
</script>

<style>
@import '~@/assets/css/common.css';

html, body {
  width: 100%;
  height: 100%;
  /* background-color: #f5f5f5; */
  overflow: hidden;
}
body {
  font-size: 16rem;
}
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
/* #app::-webkit-scrollbar {
  width: 8rem;
}
#app::-webkit-scrollbar-button {
  display: none;
}
#app::-webkit-scrollbar-thumb {
  background-color: #00000066;
  border-radius: 6px;
  cursor: pointer;
}
#app::-webkit-scrollbar-thumb:active {
  cursor: grab;
}
#app::-webkit-scrollbar-thumb:hover {
  background-color: #000000aa;
}
#app::-webkit-scrollbar-track {
  background-color: #00000022;
} */
.scroll-y-container {
  overflow-y: overlay !important;
}
</style>

<style scoped>
.nav-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
}
.nav-enter-active {
  transition: all 0.5s;
  opacity: 1;
  transform: translateY(0);
}
.nav-enter {
  opacity: 0;
  transform: translateY(-100%);
}
.nav-leave-active {
  transition: all 0.5s;
  opacity: 1;
  transform: translateY(-100%);
}
.nav-leave {
  opacity: 1;
  transform: translateY(0);
}
</style>
