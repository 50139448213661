<template>
  <div
    class="nav-bar-wrapper"
    :class="{
      'nav-bar-shadow': shadow,
    }"
    :style="{ backgroundColor: bgColor }"
  >
    <div
      class="nav-bar"
      :class="'nav-bar-' + theme"
    >
      <div class="nav-left">
        <div
          class="logo-wrapper"
          @click="$router.push('/')"
        >
          <img
            :src="'/static/img/logo-' + theme +'.png'"
            alt=""
            style="height: 100%;"
          />
        </div>
      </div>
      <div class="nav-center">
        <div class="nav-menus">
          <div
            v-for="menu in menus"
            :key="menu.path"
            class="nav-menu"
            :class="{ 'current-route': $route.path.startsWith(menu.path) && menu.path !== '/' }"
            @click.stop="menu.children || menu.jump ? winOpen(menu.jump) : $router.push({ path: menu.hash ? '' : menu.path , hash: menu?.hash || ''})"
          >
            <span>{{ menu.title }}</span>
            <div
              class="nav-submenus"
              v-if="menu.children"
            >
              <div>
                <div
                  v-for="childMenu in menu.children"
                  :key="childMenu.title + childMenu.path"
                  class="nav-submenu"
                  :class="{ 'current-route': $route.path === childMenu.path }"
                  @click.stop="$router.push({path:childMenu.path, hash: childMenu?.hash || ''} )"
                >
                  <span>{{ childMenu.title }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="nav-right">
        <div class="qr-codes">
          <div
            v-for="qr in qrs"
            :key="qr.icon"
            class="qr-code"
            v-on="qr.listeners"
          >
            <img
              :src="qr.icon"
              alt=""
            />
            <img
              class="weixin"
              src="/static/img/foot/code1.png"
            >
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    theme: { validator: v => ['dark', 'light'].includes(v), default: 'dark' },
    bgColor: { type: String, default: '#ffffff00' },
    shadow: { type: Boolean, default: false },
  },
  data () {
    return {
      menus: [
        {
          title: '首页',
          path: '/home',
        },
        { title: '关于我们', path: '/about' },
        {
          title: '业务领域',
          children: [
            { title: '资本服务', path: '/CS' },
            { title: '高新技术', path: '/TECH' },
            { title: '能源与环境', path: '/EE' },
            { title: '全球供应链', path: '/SCM' },
            { title: '商业管理', path: '/BM' },
            { title: '健康产业', path: '/HI' },
            { title: '基础设施', path: '/BI' },
            { title: '未来农业', path: '/AD' },
          ],
        },
        {
          title: '新闻资讯',
          children: [
            { title: '公告通知', path: '/newsTrend' },
            { title: '公司资讯', path: '/companyInformation' },
            { title: '政策法规', path: '/policyRegulations' },
            { title: '行业动态', path: '/industryDynamics' },
          ],
        },
        {
          title: '投资与合作',
          children: [
            { title: '投资者', path: '/investor' },
            { title: '合作邀约', path: '/cooperationOffer' },
            { title: '合作伙伴', path: '/businesspartner' },
          ],
        },
        {
          title: '人才招募',
          children: [
            { title: '人才战略', path: '/recruit/talentStrategy' },
            { title: '招募信息', path: '/recruit/socialRecruit' },
          ],
        },
        { title: '联系我们', path: '/CU' },
        // {
        //   title: '供应商登录',
        //   jump: 'http://117.72.41.220:9097',
        // },
        {
          title: '供应商登录',
          path: '/',
        },
        {
          title: '登录',
          path: '/',
        },
      ],
    };
  },
  computed: {
    qrs () {
      return [
        { icon: '/static/img/nav/icon-wechat-' + this.theme + '.png' },
        { icon: '/static/img/nav/icon-email-' + this.theme + '.png', listeners: { click: this.handlePost } },
        { icon: '/static/img/nav/icon-earth-' + this.theme + '.png' },
      ];
    },
  },
  methods: {
    handlePost () {
      const a = document.createElement('a');
      a.href = 'mailto:why@shtril.com';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    winOpen(url) {
      if(url) window.open(url ,'_blank')
    }
  },
};
</script>

<style scoped>
.nav-bar-wrapper {
  width: 100%;
}
.nav-bar-shadow {
  box-shadow: 0 5rem 20rem #00000016;
}
.nav-bar {
  width: 1920rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28rem 60rem;
  box-sizing: border-box;
  transition: background-color .3s;
}
.nav-left {
  width: 0;
  flex: 1;
}
.logo-wrapper {
  width: 460rem;
  height: 76rem;
  cursor: pointer;
}
.nav-menus {
  display: flex;
}
.nav-center {
  padding-left: 300rem;
  box-sizing: border-box;
}
.nav-menu {
  font-family: MicrosoftYaHeiLight;
  font-weight: 300;
  font-size: 20rem;
  line-height: 76rem;
  cursor: pointer;
  position: relative;
}
.nav-menu:not(:last-of-type) {
  /* margin-right: 100rem; */
  margin-right: 70rem;
}
.nav-menu:hover::after {
  content: '';
  position: absolute;
  bottom: 20rem;
  left: 0;
  width: 100%;
  height: 2rem;
  background-color: #F0D145;
}
.nav-submenus {
  position: absolute;
  top: 100%;
  left: 0;
  padding: 10rem 0;
  display: none;
}
.nav-submenus > div {
  padding: 10rem 30rem;
  border-radius: 4rem;
  box-shadow: 0 5rem 20rem #00000016;
}
.nav-submenu {
  font-size: 18rem;
  line-height: 42rem;
  text-align: center;
  white-space: nowrap;
}
.nav-menu:hover .nav-submenus {
  display: block;
}
.nav-right {
  width: 0;
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
/* theme-dark --- start */
.nav-bar-dark {
  color: #ffffff;
}
.nav-bar-dark .nav-submenus > div {
  background-color: rgba(255, 255, 255, 0.98);
}
.nav-bar-dark .nav-submenu {
  color: rgba(17, 17, 17, 0.6);
}
.nav-bar-dark .nav-submenu:hover {
  color: rgba(17, 17, 17, 1);
}
.nav-bar-dark .nav-submenu.current-route {
  color: rgba(17, 17, 17, 1);
}
.nav-bar-dark .nav-submenu:not(:last-child) {
  border-bottom: 1rem solid rgba(0, 0, 0, 0.1);
}
/* theme-dark --- end */
/* theme-light --- start */
.nav-bar-light {
  color: #111111;
}
.nav-bar-light .nav-submenus > div {
  background-color: rgba(255, 255, 255, 0.98);
}
.nav-bar-light .nav-submenu {
  color: rgba(17, 17, 17, 0.6);
}
.nav-bar-light .nav-submenu:hover {
  color: rgba(17, 17, 17, 1);
}
.nav-bar-light .nav-submenu.current-route {
  color: rgba(17, 17, 17, 1);
}
.nav-bar-light .nav-submenu:not(:last-child) {
  border-bottom: 1rem solid rgba(0, 0, 0, 0.1);
}
/* theme-light --- end */
.qr-codes {
  display: flex;
  align-items: center;
  position: relative;
}
.qr-code {
  cursor: pointer;
}
.qr-code + .qr-code {
  margin-left: 69rem;
}
.weixin{
  width: 71rem;
  position: absolute;
  top:50rem;
  display: none;
}
.qr-codes .qr-code:last-child {
  margin-left: 59rem;
}
.qr-codes .qr-code:first-child:hover .weixin{
  display: block;
}
</style>
